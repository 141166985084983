import logo from './logo.svg';
import './App.css';
import SecurePayment from "./SecurePayment"
import EduPayment from "./components/Edupay"
import {Routes, Route} from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';
function App() {
  return (
   

   <Routes>
   <Route path="/:id" element={<SecurePayment/>} />
   {/* <Route path="/emotions" element={<EduPayment/>}  /> */}
 </Routes>
 
  );
}

export default App;
